<details
  *ngIf="languageOptions?.length && sourceLanguage"
  (toggle)="toggle($event.target)"
  class="language-switcher"
>
  <summary class="language-switcher--toggle">
    <!-- See Source: /src/assets/icons/globe.svg -->
    <svg
      class="language-switcher--icon"
      viewBox="0 0 512 512"
      aria-hidden="true"
    >
      <!-- prettier-ignore -->
      <g fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"><path d="M256 48a208 208 0 1 0 0 416 208 208 0 0 0 0-416z" /><path d="M256 48c-58 0-113 93-113 208s55 208 113 208 113-93 113-208S314 48 256 48z" /><path d="M256 48v416m208-208H48" /><path miterlimit="0" stroke-linecap="round" stroke-linejoin="round" d="M117 117a240 240 0 0 0 277 0m1 278a240 240 0 0 0-277 0" /></g>
      <!-- prettier-ignore-end -->
    </svg>
    <span
      translate="no"
      [attr.title]="currentLanguageLabel"
      [attr.aria-label]="currentLanguageLabel"
      class="text-style--alt"
    >
      <span [attr.aria-hidden]="!!currentLanguageLabel">{{
        sourceLanguage
      }}</span>
    </span>
    <svg
      class="language-switcher--marker"
      aria-hidden="true"
      viewBox="0 0 16 16"
    >
      <!-- prettier-ignore -->
      <path fill="currentColor" d="M15 5.1a1.1 1.1 0 0 1-.4.9l-5.8 5.7a1.2 1.2 0 0 1-.8.3 1.2 1.2 0 0 1-.8-.3L1.3 6a1.1 1.1 0 0 1 0-1.7 1.2 1.2 0 0 1 1.7 0l5 5 5-5a1.2 1.2 0 0 1 1.3-.2 1.1 1.1 0 0 1 .7 1z" />
      <!-- prettier-ignore-end -->
    </svg>
  </summary>
  <div class="language-switcher--contents text-area--level">
    <ul
      class="ion-no-margin list-flat"
      role="list"
    >
      <li *ngFor="let targetLanguage of languageOptions">
        <a
          *ngIf="targetLanguage.label && targetLanguage.url"
          [href]="targetLanguage.url"
          [attr.lang]="targetLanguage.key"
          [attr.aria-current]="
            targetLanguage.key === sourceLanguage ? true : null
          "
          rel="alternate"
          [hreflang]="
            targetLanguage.key === sourceLanguage
              ? targetLanguage.key + ' x-default'
              : targetLanguage.key
          "
          translate="no"
          [class]="{
            'language-switcher--option': true,
            'language-switcher--selected':
              isCurrentLanguageActive && targetLanguage.key === sourceLanguage,
          }"
        >
          <span>{{ targetLanguage.label }}</span>
          <svg
            *ngIf="
              isCurrentLanguageActive && targetLanguage.key === sourceLanguage
            "
            class="language-switcher--indicator"
            aria-hidden="true"
            viewBox="0 0 16 16"
          >
            <!-- prettier-ignore -->
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.8" d="m1.4 7.2 5 5.7L14.5 3" />
            <!-- prettier-ignore-end -->
          </svg>
        </a>
      </li>
    </ul>

    <div
      *ngIf="languageOptionsExplanation"
      class="language-switcher--explanation"
    >
      <small>{{ languageOptionsExplanation }}</small>
    </div>
  </div>
</details>
